import { Box, Container, Typography } from '@mui/material';
import React from 'react';

const SuperHero = (props) => {
    return (
        <Box
            component={'section'}
            sx={(theme) => ({
                backgroundColor: 'background.paper',
                pt: {
                    xs: theme.spacing(10),
                    sm: theme.spacing(12),
                },
                pb: {
                    xs: theme.spacing(8),
                    sm: theme.spacing(10),
                },
            })}
        >
            <Container maxWidth="lg">
                <Typography
                    sx={{
                        lineHeight: 0.9,
                        fontSize: {
                            xs: `${50 / 16}rem`,
                            md: `${100 / 16}rem`,
                        },
                        maxWidth: {
                            xs: 350,
                            md: 400,
                        },
                    }}
                    variant="h1"
                    gutterBottom
                >
                    {props.title}
                </Typography>

                <Typography
                    sx={{
                        maxWidth: 820,
                    }}
                    component="div"
                    variant="body1"
                    align="left"
                    paragraph
                >
                    {props.children}
                </Typography>

                <Box
                    sx={{
                        mt: 4,
                        display: 'flex',
                        flexDirection: {
                            xs: 'column',
                            sm: 'row',
                        },
                        justifyContent: {
                            sm: 'space-between',
                        },
                        alignItems: {
                            sm: 'flex-start',
                        },
                        maxWidth: {
                            sm: 420,
                        },
                    }}
                ></Box>
            </Container>
        </Box>
    );
};

export default SuperHero;
