import { Container, Divider, Typography } from '@mui/material';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { getModuleCollectionSlug } from '../../util/slug-util';
import ChatBubbleItem from '../navbar/chat-bubble-item';

import ModuleListElement from './module-list-element';

const moduleListQuery = graphql`
    query ModuleListQuery {
        allMdx(
            filter: { fields: { pageType: { eq: "module-index-page" } } }
            sort: { fields: [frontmatter___order], order: ASC }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        published
                    }
                    fields {
                        slug
                        collection
                    }
                }
            }
        }
    }
`;

const ModuleList = (props) => {
    const { title, subtitle } = props;

    const data = useStaticQuery(moduleListQuery);

    const moduleCollection = getModuleCollectionSlug();

    return (
        <Container
            component="section"
            sx={{
                paddingTop: (theme) => theme.spacing(4),
                paddingBottom: (theme) => theme.spacing(4),
            }}
            maxWidth="lg"
        >
            <Typography
                sx={{
                    '&::before': {
                        content: '""',
                        display: 'block',
                        height: 10,
                    },
                }}
                variant="h2"
            >
                {title}
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
                {subtitle}
            </Typography>
            <div id="accordion-list">
                {data?.allMdx?.edges
                    .filter((n: any) => n.node.fields.collection === moduleCollection)
                    .map(({ node }, index) => (
                        <ModuleListElement
                            key={node.id}
                            slug={node.fields.slug}
                            title={node.frontmatter.title}
                            published={props.published ? node.frontmatter.published : false}
                            order={index + 1}
                        />
                    ))}
            </div>
            <Divider sx={{ mt: 2, mb: 4 }} />
            <ChatBubbleItem sx={{ml: 'auto', mr: 'auto', display: 'flex'}}/>
        </Container>
    );
};

export default ModuleList;
