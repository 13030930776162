import { Box, Container, Typography } from '@mui/material';
import React, { PropsWithChildren, useState } from 'react';
import CustomButton from '../../button';
import ModuleCollectionListContent from '../../course-modules/module-collection-list-content';
import StartHereGraphic from './start-here-graphic';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { Trans } from 'react-i18next';

export interface Props {
    moduleFilterCategory: string;
    setModuleFilterCategory: React.Dispatch<React.SetStateAction<string>>;
}

export default function MainModuleCollectionWithExperience({
    moduleFilterCategory,
    setModuleFilterCategory,
}: PropsWithChildren<Props>) {
    const beginnerCourses = [
        'Johdatus ohjelmointiin',
        'Data ja tieto',
        'Internet ja selainohjelmointi',
    ];

    const experiencedCourses = [
        'Data ja tieto',
        'Internet ja selainohjelmointi',
        'Mobiilisovellukset ja niiden luominen',
        'Web Software Development',
        'Device-Agnostic Design',
        'Designing and Building Scalable Web Applications',
    ];

    const beginnerFilter = (n: any) => beginnerCourses.includes(n.node.frontmatter.title);
    const programmerFilter = (n: any) => experiencedCourses.includes(n.node.frontmatter.title);

    let experienceFilter = (n: any) => true;
    if (moduleFilterCategory === 'beginner') experienceFilter = beginnerFilter;
    if (moduleFilterCategory === 'programmer') experienceFilter = programmerFilter;

    const scrollToModules = () => {
        setTimeout(() => {
            scrollTo(`#module-collection-list-container`);
        }, 100);
    };

    return (
        <Container maxWidth="lg">
            <Container
                maxWidth="md"
                sx={{
                    position: 'relative',
                    minHeight: '20vh',
                    py: 4,
                    backgroundColor: 'white',
                    boxShadow: '0 0 0 100vmax white',
                    clipPath: 'inset(0 -100vmax)',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        flexWrap: 'wrap',
                        gap: 4,
                    }}
                >
                    <CustomButton
                        sx={{
                            flexShrink: '0',
                            fontSize: { xs: '32px', md: '42px' },
                            minWidth: { xs: '250px', md: '340px' },
                        }}
                        disabled={moduleFilterCategory === 'beginner'}
                        onClick={() => {
                            setModuleFilterCategory('beginner');
                            scrollToModules();
                        }}
                        kind="fitech"
                        color="fitech"
                        variant="contained"
                    >
                        <Trans i18nKey="imaBeginner" />
                    </CustomButton>
                    <CustomButton
                        sx={{
                            flexShrink: '0',
                            fontSize: { xs: '32px', md: '42px' },
                            minWidth: { xs: '250px', md: '340px' },
                        }}
                        disabled={moduleFilterCategory === 'programmer'}
                        onClick={() => {
                            setModuleFilterCategory('programmer');
                            scrollToModules();
                        }}
                        kind="fitech"
                        color="secondary"
                        variant="contained"
                    >
                        <Trans i18nKey="knowProgramming" />
                    </CustomButton>
                    {moduleFilterCategory !== 'all' && (
                        <CustomButton
                            sx={{
                                flexShrink: '0',
                                fontSize: { xs: '32px', md: '42px' },
                                minWidth: { xs: '250px', md: '340px' },
                            }}
                            disabled={moduleFilterCategory === 'all'}
                            onClick={() => setModuleFilterCategory('all')}
                            kind="fitech"
                            color="inherit"
                            variant="contained"
                        >
                            <Trans i18nKey="showAllCourses" />
                        </CustomButton>
                    )}
                </Box>
            </Container>
            <Container
                maxWidth="xl"
                sx={{
                    backgroundColor: 'white',
                    boxShadow: '0 0 0 100vmax white',
                    clipPath: 'inset(0 -100vmax)',
                    overflow: 'visible',
                    py: 4,
                }}
            >
                <Typography
                    variant="h1"
                    component="h2"
                    align="center"
                    gutterBottom
                    id="module-collection-list-container"
                >
                    <Trans i18nKey="courses" />
                </Typography>
                <Container disableGutters sx={{ position: 'relative', py: 8 }}>
                    {moduleFilterCategory !== 'programmer' && <StartHereGraphic />}
                    <ModuleCollectionListContent published filterFunction={experienceFilter} />
                    <Box display="flex" justifyContent="center" alignItems="center" py={8}>
                        {moduleFilterCategory !== 'all' && (
                            <CustomButton
                                sx={{
                                    flexShrink: '0',
                                    fontSize: { xs: '32px', md: '42px' },
                                    minWidth: { xs: '250px', md: '340px' },
                                }}
                                disabled={moduleFilterCategory === 'all'}
                                onClick={() => setModuleFilterCategory('all')}
                                kind="fitech"
                                color="inherit"
                                variant="contained"
                            >
                                <Trans i18nKey="showAllCourses" />
                            </CustomButton>
                        )}
                    </Box>
                </Container>
            </Container>
        </Container>
    );
}
