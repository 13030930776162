import { Container } from '@mui/material';
import React from 'react';

const ModuleCollectionListWrapper = (props) => {
    return (
        <Container
            component={'section'}
            sx={{ backgroundColor: props.published ? 'inherit' : 'background.paper' }}
            id={props.published ? 'currentlyActiveModules' : 'currentlyInactiveModules'}
        >
            <div
                style={{
                    backgroundColor: 'inherit',
                    margin: '0 -600rem',
                    padding: '0 600rem',
                }}
            >
                <Container
                    sx={{
                        paddingTop: (theme) => theme.spacing(4),
                        paddingBottom: (theme) => theme.spacing(4),
                    }}
                    maxWidth="lg"
                >
                   {props.children}
                </Container>
            </div>
        </Container>
    );
};

export default ModuleCollectionListWrapper;
