import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import i18next from 'i18next';
import localStorageUtil from '../../../util/local-storage-util';

export default function MainLanguageSwitch() {
    const handleChange = (event: SelectChangeEvent) => {
        const newLang = event.target.value;
        localStorageUtil.setItem('lang', newLang);
        i18next.changeLanguage(newLang);
        window && window.location.reload();
    };

    return (
        <Box sx={{ position: 'fixed', top: 112, right: 64, display: 'flex', zIndex: 1000 }}>
            <FormControl sx={{ m: 1, minWidth: 96 }} size="small">
                <InputLabel id="landing-language-select">Language</InputLabel>
                <Select
                    labelId="landing-language-select"
                    id="landing-language-select"
                    value={i18next.resolvedLanguage}
                    label="Language"
                    onChange={handleChange}
                >
                    <MenuItem value="fi">FI</MenuItem>
                    <MenuItem value="en">EN</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}
