import React from 'react';
import ModuleCollectionListWrapper from './module-collection-list-wrapper';
import ModuleCollectionListContent from './module-collection-list-content';

const ModuleCollectionList = (props: { published: boolean; }) => {
   
    return (
        <ModuleCollectionListWrapper publsihed={props.published}>
            <ModuleCollectionListContent published={props.published} />
        </ModuleCollectionListWrapper>
    );
};

export default ModuleCollectionList;
