import { Box, Container, Typography } from '@mui/material';
import { navigate } from 'gatsby';
import { Trans } from 'react-i18next';
import scrollTo from 'gatsby-plugin-smoothscroll';
import React, { useContext } from 'react';

import { postAnalyticsData } from '../../http-actions/analytics-actions';
import { AuthContext } from '../../providers/auth-provider';
import { NavigationInfoContext } from '../../providers/navigation-info-provider';
import Button from '../button';

/*
TODO
MAKE NAVBUTTONS LINKS
*/

const importAll = (require) =>
    require.keys().reduce((acc, next) => {
        acc[next.replace('./', '')] = require(next);
        return acc;
    }, {});

let images;
try {
    images = importAll(require.context('../../images/splashes', false, /\.(svg)$/));
} catch (e) {
    console.log('Cannot read from ../../images/splashes');
    console.log(e);
}

interface HeroWithGraphicPropsI {
    title: string;
    titleWidth?: number;
    showButtons?: boolean;
    singleCourse?: boolean;
    backgroundColor?: string;
    imageFile?: string;
    graphicHeight?: number;
    children: React.ReactNode;
    hasMoreInfo?: boolean;
}

const HeroWithGraphic = (props: HeroWithGraphicPropsI) => {
    const {
        state: { pathToFirstPart },
    } = useContext(NavigationInfoContext);

    console.log('FIRST PART OF COURSE', pathToFirstPart);

    const scrollToModules = () => {
        scrollTo('#currentlyActiveModules');
    };

    const scrollToAdditionalInformation = () => {
        scrollTo('#readMoreAboutIt');
    };

    const { state: authState } = useContext(AuthContext);

    const logPageNavigation = async (path: string, buttonId: string) => {
        void postAnalyticsData(authState.token, 'navigation', {
            navigate_to: path,
            type: 'front-page-navigation',
            buttonId,
        });
    };

    const navigateToPage = (path: string, buttonId: string) => () => {
        void logPageNavigation(path, buttonId);
        void navigate(path);
    };

    return (
        <Box
            component="section"
            sx={{
                backgroundColor: props.backgroundColor || '#D4E5FF',
                paddingTop: (theme) => theme.spacing(4),
                paddingBottom: (theme) => theme.spacing(4),
            }}
        >
            <Container
                maxWidth="lg"
                sx={{
                    p: 8,
                    display: {
                        xs: 'block',
                        md: 'grid',
                    },
                    height: 'fit-content',
                    gridTemplateColumns: '1fr 1fr',
                }}
            >
                <Box display="flex" justifyContent="center" flexDirection="column" pr={2}>
                    <Typography
                        sx={{
                            lineHeight: 0.9,
                            fontSize: {
                                xs: `${75 / 16}rem`,
                                md: `${100 / 16}rem`,
                            },
                            maxWidth: {
                                xs: 400,
                                md: props.titleWidth || 500,
                            },
                        }}
                        variant="h1"
                        gutterBottom
                    >
                        {props.title}
                    </Typography>

                    <Typography
                        sx={{ maxWidth: 820 }}
                        component="div"
                        variant="body1"
                        align="left"
                        paragraph
                    >
                        {props.children}
                    </Typography>

                    {props.showButtons && (
                        <Box
                            sx={{
                                mt: 4,
                                display: 'flex',
                                flexDirection: {
                                    xs: 'column',
                                    sm: 'row',
                                },
                                justifyContent: {
                                    sm: 'space-between',
                                },
                                alignItems: {
                                    sm: 'flex-start',
                                },
                                maxWidth: {
                                    sm: 420,
                                },
                            }}
                        >
                            <Button
                                color="inherit"
                                id="start-now"
                                kind="fitech"
                                sx={{
                                    color: 'fitech.contrastText',
                                    backgroundColor: 'fitech.main',
                                    marginBottom: (theme) => theme.spacing(1),
                                    transition: (theme) =>
                                        theme.transitions.create(['color', 'background-color']),
                                    fontSize: {
                                        xs: `${30 / 16}rem`,
                                        md: `${36 / 16}rem`,
                                    },
                                    width: {
                                        xs: 'inherit',
                                        sm: 200,
                                    },
                                    '&:hover': {
                                        color: 'common.black',
                                    },
                                }}
                                elevation={0}
                                onClick={
                                    props.singleCourse
                                        ? navigateToPage(pathToFirstPart, 'start-now')
                                        : scrollToModules
                                }
                            >
                                <Trans i18nKey="startNow" />!
                            </Button>

                            {props.hasMoreInfo && (
                                <Button
                                    color="inherit"
                                    id="more-info-scroll"
                                    kind="fitech"
                                    sx={{
                                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                                        fontSize: {
                                            xs: `${30 / 16}rem`,
                                            md: `${36 / 16}rem`,
                                        },
                                        width: {
                                            xs: 'inherit',
                                            sm: 200,
                                        },
                                    }}
                                    elevation={0}
                                    onClick={scrollToAdditionalInformation}
                                >
                                    <Trans i18nKey="readMore" />
                                </Button>
                            )}
                        </Box>
                    )}
                </Box>
                <Box
                    sx={{
                        Width: '100%',
                        display: {
                            xs: 'none',
                            md: 'inherit',
                        },
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            height: props.graphicHeight || '100%',
                        }}
                        src={images[props.imageFile].default}
                        alt="Cloud with databases on top. Buzzwords displayed in rain from the cloud. The rain falls toward a phone."
                    />
                </Box>
            </Container>
        </Box>
    );
};

export default HeroWithGraphic;
