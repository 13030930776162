import { Box } from '@mui/material';
import React from 'react';

const importAll = (require) =>
    require.keys().reduce((acc, next) => {
        acc[next.replace('./', '')] = require(next);
        return acc;
    }, {});

let images;
try {
    images = importAll(require.context('../../../images/landing-bg', false, /\.(svg)$/));
} catch (e) {
    console.log('Cannot read from ../../../images/landing-bg');
    console.log(e);
}

export default function MainGraphic() {
    return (
        <Box sx={{ position: 'absolute' }}>
            <Box
                sx={{ pointerEvents: 'none' }}
                component="img"
                width="1250px"
                src={images["main-bg-graphic-subtle.svg"].default}
                alt="Outlined geometric shapes"
            />
        </Box>
    );
}
