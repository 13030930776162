import { Container, Grid, Typography } from '@mui/material';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { getModuleCollectionSlug } from '../../util/slug-util';
import { Trans } from 'react-i18next';
import CourseCard from './course-card';

/*
TODO
DEFINE COLOR IN THEME
*/

const moduleCollectionQuery = graphql`
    query ModuleCollectionQuery {
        allMdx(
            filter: { fields: { pageType: { eq: "module-index-page" } } }
            sort: { fields: [frontmatter___order], order: ASC }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        metatext
                        published
                        image
                        release
                        workload
                    }
                    fields {
                        slug
                        collection
                    }
                }
            }
        }
    }
`;

const CourseCardCollection = (props) => {
    const data = useStaticQuery(moduleCollectionQuery);
    const moduleCollection = getModuleCollectionSlug();

    return (
        <Container
            component={'section'}
            sx={{ backgroundColor: props.published ? 'inherit' : 'background.paper' }}
            id={props.published ? 'currentlyActiveModules' : 'currentlyInactiveModules'}
        >
            <div
                style={{
                    backgroundColor: 'inherit',
                    margin: '0 -600rem',
                    padding: '0 600rem',
                }}
            >
                <Container
                    sx={{
                        paddingTop: (theme) => theme.spacing(4),
                        paddingBottom: (theme) => theme.spacing(4),
                    }}
                    maxWidth="lg"
                >
                    <Typography
                        sx={{
                            '&::before': {
                                content: '""',
                                display: 'block',
                                height: 10,
                            },
                        }}
                        variant="h2"
                    >
                        <Trans i18nKey={props.published ? 'availableCourses' : 'futureCourses'} />
                    </Typography>
                    <Typography variant="body1" color="textSecondary" paragraph>
                        <Trans
                            i18nKey={
                                props.published
                                    ? 'availableCoursesInfo'
                                    : 'futureCoursesInfo'
                            }
                        />
                    </Typography>
                    {data?.allMdx?.edges && (
                        <Grid container spacing={2} direction="row">
                            {data.allMdx.edges
                                .filter(
                                    (n: any) =>
                                        n.node.frontmatter.published === props.published &&
                                        n.node.fields.collection === moduleCollection,
                                )
                                .map(({ node }: any) => (
                                    <Grid item xs={12} sm={6} md={4} key={'gi-' + node.id}>
                                        <CourseCard
                                            key={node.id}
                                            slug={node.fields.slug}
                                            title={node.frontmatter.title}
                                            metatext={node.frontmatter.metatext}
                                            image={node.frontmatter.image}
                                            published={props.published}
                                            release={node.frontmatter.release}
                                            workload={node.frontmatter.workload}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                    )}
                </Container>
            </div>
        </Container>
    );
};

export default CourseCardCollection;
