import { Card, CardContent, CardMedia, LinearProgress, Typography } from '@mui/material';
import { Link } from 'gatsby';
import React from 'react';

import { getModuleProgressPercentage, moduleHasPoints } from '../../util/point-utils';

import { useStore } from '@nanostores/react'
import { points } from '../../stores/points.ts';

/*
TODO
SEPARATE COMPONENTS INTO FILES
*/

const importAll = (require) =>
    require.keys().reduce((acc, next) => {
        acc[next.replace('./', '')] = require(next);
        return acc;
    }, {});

let images;
try {
    images = importAll(require.context('../../images/covers', false, /\.(png|jpe?g|svg)$/));
} catch (e) {
    console.log('Cannot read from ../../images/covers');
    console.log(e);
}

const CourseCardContent = (props) => {
    const imageUrl =
        props.image && images && images[props.image] ? images[props.image].default : null;

    const pointStore = useStore(points);

    const slugParts = props.slug.replace(/^\/|\/$/g, '').split('/');

    console.log("slugParts", slugParts);
    
    let modulePointPercentage = undefined;
    let hasPoints = false;
    if (slugParts.length === 1) {
        const moduleCollectionSlug = slugParts[0];
        hasPoints = moduleHasPoints(pointStore, moduleCollectionSlug);
        modulePointPercentage = hasPoints ? getModuleProgressPercentage(pointStore, moduleCollectionSlug) : undefined;
    } else if (slugParts.length === 2) {
        const moduleCollectionSlug = slugParts[0];
        const moduleName = slugParts[1];

        hasPoints = moduleHasPoints(pointStore, moduleCollectionSlug, moduleName);
        modulePointPercentage = hasPoints ? getModuleProgressPercentage(pointStore, moduleCollectionSlug, moduleName) : undefined;
    }

    console.log("has points?", hasPoints);

    return (
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                '&:hover': {
                    boxShadow: (theme) => props.published && theme.shadows[3],
                },
            }}
            square
        >
            <CardMedia
                sx={{
                    height: 0,
                    paddingTop: '56.25%',
                }}
                image={imageUrl}
                title={props.title}
                style={{
                    opacity: props.published ? 1 : 0.4,
                }}
            />
            {props.published && hasPoints && (
                <LinearProgress
                    sx={{
                        height: 8,
                        backgroundColor: '#4caf50',
                        '.MuiLinearProgress-bar': {
                            borderRadius: 20,
                            backgroundColor: '#2e7d32',
                        },
                    }}
                    variant="determinate"
                    color="secondary"
                    value={modulePointPercentage}
                />
            )}
            <CardContent sx={{ flexGrow: 1 }}>
                <Typography
                    sx={{ fontSize: `${24 / 16}rem` }}
                    variant="h3"
                    color={props.published ? 'textPrimary' : 'textSecondary'}
                    gutterBottom
                >
                    {props.title}
                </Typography>
                <Typography
                    variant="body2"
                    color={props.published ? 'textPrimary' : 'textSecondary'}
                    component="p"
                    gutterBottom
                >
                    {props.metatext}
                </Typography>
            </CardContent>
            <CardContent sx={{ paddingTop: 0 }}>
                <Typography variant="body2" color="textSecondary" align="right">
                    {props.published ? props.workload : props.release}
                </Typography>
            </CardContent>
        </Card>
    );
};

const CourseCard = (props) => {
    if (props.published) {
        return (
            <Link to={props.slug} style={{ textDecoration: 'none' }}>
                <CourseCardContent {...props} />
            </Link>
        );
    } else {
        return <CourseCardContent {...props} />;
    }
};

export default CourseCard;
