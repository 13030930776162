import {
    Avatar,
    Box,
    Container,
    Typography,
} from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CategoryIcon from '@mui/icons-material/Category';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import StarIcon from '@mui/icons-material/Star';
import React from 'react';

/*
TODO
CLEAN UP COMMENTS
DEFINE COLORS IN THEME
*/

const DetailBlock = (props) => {
    return (
        <Box component={'div'} sx={(theme) => ({
            display: 'flex',
            m: 2,
            p: 2
        })}>
            <Box sx={{
                display: {
                    xs: 'none',
                    md: 'inherit'
                }
            }}>
                <Avatar sx={{
                    margin: theme => theme.spacing(0, 4, 0, 0),
                }}>
                    {props.icon}
                </Avatar>
            </Box>
            <Box component={'div'} sx={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Typography variant="h2" gutterBottom>
                    {props.title}
                </Typography>
                {props.descriptions.map((desc) =>
                    <Typography variant="body1"
                        align="left"
                        paragraph
                        dangerouslySetInnerHTML={{ __html: desc }}
                    />
                )}
            </Box>
        </Box>
    )
};


const AdditionalDetails = (props) => {

    return (
        <Box component={'section'} sx={(theme) => ({
            flexGrow: 1,
            padding: theme.spacing(4, 0, 6),
        })} id="readMoreAboutIt">
            <Container maxWidth="lg">
                <DetailBlock
                    title={'FITech 101: Digi & Data'}
                    descriptions={[`FITech 101: Digi & Data on ilmainen tietotekniikan ydinasiat kaikille
                    opettava kokonaisuus. Aloittaminen on helppoa -- valitse yllä olevasta
                    listauksesta kurssi, josta olet kiinnostunut, ja ala tekemään. Kun olet
                    saanut kurssin suoritettua, saat ohjeet Aalto-yliopiston virallisten
                    opintopisteiden sekä LinkedIn-badgen saamiseen.`]}
                    icon={<CategoryIcon />}
                />
                <DetailBlock
                    title={'Mikä ihmeen FITech?'}
                    descriptions={[`FITech-verkostoyliopisto tarjoaa valikoituja opintoja kaikista tekniikan
                    alan yliopistoista Suomessa. Voit päivittää täysin maksuttomasti
                    erityisesti digiosaamistasi. Kurssit ovat kaikille avoimia ja niitä
                    voivat suorittaa kaikki lukiolaisista työikäisiin.`, `Lue lisää: <a
                    href="https://FITech.io/fi/" target="_blank" rel="noopener noreferrer">FITech.io</a>`]}
                    icon={<HelpOutlineIcon />}
                />
                <DetailBlock
                    title={'Opiskelijalle'}
                    descriptions={[`FITech 101: Digi & Data -opintokokonaisuuden opinnot ovat yliopistotason
                    perusopintoja. Opinnot voidaan lukea osaksi omaa tutkintoasi oman
                    oppilaitoksesi sääntöjen puitteissa. Kysy opintojen hyväksiluvusta myös
                    lukiosi tai ammattikoulusi opinto-ohjaajalta.`]}
                    icon={<StarIcon />}
                />
                <DetailBlock
                    title={'Opettajalle'}
                    descriptions={[`Voit käyttää tätä sivustoa vapaasti omassa opetuksessasi. Nämä
                    oppimateriaalit ovat ilmaiseksi saatavilla ja käytettävissä vähintään vuoden 2023
                    loppuun asti.`]}
                    icon={<StarIcon />}
                />
            </Container>
        </Box>
    );
};

export default AdditionalDetails;
