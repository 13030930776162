import { Box, Typography } from '@mui/material';
import React from 'react';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import { Trans } from 'react-i18next';

export default function StartHereGraphic() {
    return (
        <Box
            sx={{ position: 'absolute', top: 0, left: { xs: '0px', sm: '50px' }, display: 'flex' }}
        >
            <SouthWestIcon
                fontSize="large"
                color="primary"
                sx={{
                    fontSize: { xs: '2.5rem', md: '3rem' },
                    color: (theme) => theme.palette.fitech.main,
                    m: 1,
                }}
            />
            <Typography
                variant="h3"
                component="span"
                sx={{ color: (theme) => theme.palette.fitech.main }}
            >
                <Trans i18nKey="beginnerStartHere" />
            </Typography>
        </Box>
    );
}
