import { Box, Button, Divider, List, ListItem } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'gatsby';
import React, { useContext } from 'react';
import { Trans } from 'react-i18next';

import { postAnalyticsData } from '../../http-actions/analytics-actions';
import { AuthContext } from '../../providers/auth-provider';
import { NavigationInfoContext } from '../../providers/navigation-info-provider';
import LinearProgressWithLabel from '../progress/linear-progress-with-label';

function ModuleListElement({ slug, title, published, order }) {
    const {
        state: { moduleMap, pointMap },
    } = useContext(NavigationInfoContext);

    // const moduleParts = moduleMap[slug.replaceAll('/', '')]?.parts;
    if (!slug) {
        return <></>;
    }

    const moduleName = slug.replace(/\/$/, '').split('/').pop();
    const moduleParts = moduleMap[moduleName]?.parts;

    const modulesWithPointsCount = () => {
        if (!moduleParts) return;
        let tot = 0;
        for (const part of Object.keys(moduleParts)) {
            if (pointMap[part]?.maxPoints > 0) {
                tot = tot + 1;
            }
        }
        if (tot === 0) return;
        return tot;
    };

    const withPointsCount = modulesWithPointsCount();

    const moduleProgress =
        (moduleParts &&
            withPointsCount &&
            Object.keys(moduleParts)?.reduce(
                (sum, current) => pointMap[current]?.percentage + sum || sum,
                0,
            ) / withPointsCount) ||
        0;

    const { state: authState } = useContext(AuthContext);

    const logPageNavigation = (path: string) => {
        void postAnalyticsData(authState.token, 'navigation', {
            navigate_to: path,
            type: 'module-list-navigation',
        });
    };

    return (
        <Accordion
            sx={{
                margin: (theme) => theme.spacing(2, 0),
                '&:before': {
                    display: 'none',
                },
            }}
            disabled={!published}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Box display="flex" flexDirection="row" alignItems="center" width="100%">
                    <Typography component="h2" variant="h3">
                        {order}
                    </Typography>
                    <Divider
                        sx={{
                            margin: (theme) => theme.spacing(0, 2),
                        }}
                        flexItem
                        orientation="vertical"
                    />
                    <Box minWidth="50%">
                        <Typography component="h2" variant="h3">
                            {title}
                        </Typography>
                    </Box>
                    <Box width="100%">
                        {withPointsCount ? (
                            <LinearProgressWithLabel value={moduleProgress} />
                        ) : (
                            <Box width="100%" display="flex" justifyContent="center">
                                <Typography variant="body2">
                                    <Trans i18nKey="noPoints" />
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Box width="100%">
                    <List>
                        {moduleParts &&
                            Object.entries(moduleParts)
                                .sort((o1, o2) => o1[1].order - o2[1].order)
                                .map(([key, part]) => (
                                    <ListItem key={key} dense>
                                        <Button
                                            key={key}
                                            sx={{
                                                textTransform: 'none',
                                                letterSpacing: 0,
                                                borderRadius: 0,
                                            }}
                                            color="inherit"
                                            fullWidth
                                            component={part.published ? Link : 'span'}
                                            to={part.published ? part.slug : undefined}
                                            onClick={() => {
                                                if (part.published) {
                                                    logPageNavigation(part.slug);
                                                }
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    minWidth: '50%',
                                                }}
                                                component="h3"
                                                variant="h4"
                                            >
                                                {part.title}
                                            </Typography>
                                            <Box width="100%">
                                                {pointMap[part.slug]?.maxPoints > 0 ? (
                                                    <LinearProgressWithLabel
                                                        value={pointMap[part.slug]?.percentage || 0}
                                                    />
                                                ) : (
                                                    <Box
                                                        width="100%"
                                                        display="flex"
                                                        justifyContent="center"
                                                    >
                                                        <Typography variant="body2">
                                                            <Trans i18nKey="noPoints" />
                                                        </Typography>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Button>
                                    </ListItem>
                                ))}
                    </List>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

export default ModuleListElement;
