import {
    Box,
    Container,
    IconButton,
    Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import TypographyLink from '../navigation/typography-link';
import React, { useContext, useEffect, useState } from 'react';

import { NavigationInfoContext } from '../../providers/navigation-info-provider';

const sxStyles = {
    dismiss: (theme) => ({
        zIndex: 1,
        position: 'absolute',
        top: {
            xs: theme.spacing(0.5),
            md: theme.spacing(1)
        },
        right: {
            xs: theme.spacing(0.5),
            md: theme.spacing(2)
        }
    }),
    link: {
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'underline',
        },
    },
    content: (theme) => ({
        display: 'grid',
        alignItems: 'center',
        gridColumnGap: theme.spacing(2),
        gridTemplateColumns: {
            xs: 'auto 560px',
            md: '100%',
        },
        gridTemplateRows: {
            xs: '200px auto',
            md: '315px',
        },
        [theme.breakpoints.down('md')]: {
            gridRowGap: theme.spacing(2),
            overflow: 'hidden',
        },
    }),
    textContent: (theme) => ({
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(4, 2, 0, 2),
            justifyContent: 'center',
        },
    }),
    title: (theme) => ({
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
    }),
    subtitle: (theme) => ({
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
    }),
};

const importAll = (require) =>
    require.keys().reduce((acc, next) => {
        acc[next.replace('./', '')] = require(next);
        return acc;
    }, {});


let images;
try {
    images = importAll(require.context('../../images/covers', false, /\.(png|jpe?g|svg)$/));
} catch (e) {
    console.log("Cannot read from ../../../images/covers");
    console.log(e);
}

const BannerAd = ({
    category,
    title,
    children,
}: {
    category: string;
    title: string;
    children: any;
}) => {
    const [visible, setVisible] = useState(false);

    const storageKey = `front-page-ad-${category}`;

    useEffect(() => {
        const frontPageAd = window.localStorage.getItem(storageKey);
        console.log('SHOWAD???', frontPageAd);
        if (!frontPageAd || frontPageAd === 'visible') {
            setVisible(true);
            window.localStorage.setItem(storageKey, 'visible');
        }
    }, []);

    const handleDismiss = () => {
        window.localStorage.setItem(storageKey, 'dismissed');
        setVisible(false);
    };

    const {
        state: { moduleMap },
    } = useContext(NavigationInfoContext);

    console.log('AD:', images);

    const advertisedNode = moduleMap[category];

    const advertisedNodeParts = advertisedNode?.parts;
    const targetPage =
        advertisedNodeParts && Object.values(advertisedNodeParts).find((part) => part.order === 1);
    const targetPageSlug = targetPage?.slug || '/';

    return visible && (
        <Box component={'section'} sx={{ position: 'relative' }}>
            <IconButton
                aria-label="close-ad"
                sx={sxStyles.dismiss}
                onClick={handleDismiss}
                size="large">
                <CloseIcon fontSize="large" />
            </IconButton>
            <Box sx={{
                backgroundColor: advertisedNode?.color ?
                    `#${advertisedNode?.color}` :
                    'inherit',
                '&:hover': {
                    opacity: 0.74,
                },
            }}>
                <TypographyLink to={targetPageSlug} sx={sxStyles.link}>
                    <Container sx={sxStyles.content} maxWidth="lg">
                        <Box sx={sxStyles.textContent}>
                            <Typography sx={sxStyles.title} variant="h1" gutterBottom>
                                {title}
                            </Typography>
                            <Typography
                                sx={sxStyles.subtitle}
                                variant="body1"
                                align="left"
                                paragraph
                            >
                                {children}
                            </Typography>
                        </Box>
                        {advertisedNode && (
                            <img
                                style={{
                                    objectFit: 'cover',
                                    maxHeight: '100%',
                                    maxWidth: '100%',
                                }}
                                src={images[advertisedNode.image].default}
                                alt="Course image"
                            />
                        )}
                    </Container>
                </TypographyLink>
            </Box>
        </Box>
    );
};

export default BannerAd;
