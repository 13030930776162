import React from 'react';


const RandomizedExperimentGroup = ({ children, ...props }) => {
    console.log('Randomized experiment group: ' + props.id);

    return (
        <>{children}</>
    );
};

export default RandomizedExperimentGroup;