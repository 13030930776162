import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../../providers/auth-provider';
import { getUserData, postExperimentDetails } from '../../../http-actions/user-actions';
import { UserContext } from '../../../providers/user-provider';

const RandomizedExperiment = (props) => {
    // console.log('---RandomizedExperiment---');
    const { state: authState } = useContext(AuthContext);
    const { state: userState, dispatch: userDispatch } = useContext(UserContext);
    const user = userState.user;

    const getUserDetails = async () => {
        // console.log('Retrieving user details');
        if (!authState.token) {
            // console.log('No token in authstate...');
            return;
        }

        await getUserData(authState.token).then((data) => {
            // console.log('Retrieved user data');
            // console.log(data);
            userDispatch({ type: 'UPDATE_USER', user: data });
        });
    };

    useEffect(() => {
        // console.log('authstate changed, retrieving user details..');
        getUserDetails();
    }, [authState]);

    // console.log('Children of randomized experiment');
    // console.log(props.children);

    const experimentName = props.name;
    if (!experimentName) {
        return <>Element must have a name!</>;
    }

    if (!props.children.length || props.children.length < 2) {
        // console.log('Did not have at least two children.');
        // console.log('Children were...');
        // console.log(props.children);
        return <>Element must have at least two child elements!</>;
    }

    const experimentGroups = props.children.filter(
        (c) =>
            c.props.mdxType === 'RandomizedExperimentGroup' ||
            c.props.reactType === 'RandomizedExperimentGroup',
    );
    if (experimentGroups.length < 2) {
        return <>Element must have at least two groups!</>;
    }

    if (experimentGroups.filter((g) => !g.props.id).length > 0) {
        // console.log('The following experiment groups did not have an id:');
        // console.log(experimentGroups.filter((g) => !g.props.id));
        return <>Every experiment group must have an id!</>;
    }

    /*
    if (experimentGroups.filter((g) => g.props.default).length <= 1) {
        return (
            <>There must be exactly one default experiment group!</>
        );
    }
    */

    // console.log('HELLO!');
    // console.log(`Experiment name: ${experimentName}`);
    // console.log('User:');
    // console.log(user);
    // console.log(experimentGroups);

    if (!user || !user.details || !user.details.consents) {
        // console.log('No user details available. Not showing content.');
        return null;
    }
    /*
    if (!user ||
        !user.details ||
        !user.details.dataCollectionSettings ||
        !user.details.dataCollectionSettings.allowShowNewFeatures) {
        console.log('No user details or user has not allowed showing new features.');
        console.log('Using default group.');

        const defaultGroup = experimentGroups.filter((g) => g.props.default)[0];
        return defaultGroup;
    }
    */

    // if user already has a condition set for this experiment, use that
    if (
        user &&
        user.details &&
        user.details.experiments &&
        user.details.experiments[experimentName]
    ) {
        // console.log('User has an existing condition for this experiment.');
        const existingCondition = user.details.experiments[experimentName];
        // console.log('Existing condition is ' + existingCondition);

        // console.log("EXPERIMENT GROUPS:", experimentGroups);

        const existingGroup = experimentGroups.filter((g) => g.props.id === existingCondition);
        if (existingGroup.length == 1) {
            // console.log('Found a group matching the condition');
            return existingGroup[0];
        }
        return <p style={{ color: 'grey' }}>{"The this content does not exist. Please, clear browser cache for this website."}</p>
    }

    // try to set a condition for the user;
    // default is not used as a part of the experiment
    const experimentDetails = {
        experimentName: experimentName,
        groups: experimentGroups.filter((g) => !g.props.default).map((g) => g.props.id),
    };

    postExperimentDetails(authState.token, experimentDetails).then((resp) =>
        getUserData(authState.token).then((data) =>
            userDispatch({ type: 'UPDATE_USER', user: data }),
        ),
    );

    return <>One moment, here should be content...</>;
};

export default RandomizedExperiment;
