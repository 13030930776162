import { Box, Container, Typography } from '@mui/material';
import { navigate } from 'gatsby';
import { Trans } from 'react-i18next';
import scrollTo from 'gatsby-plugin-smoothscroll';
import React, { useContext } from 'react';

import { postAnalyticsData } from '../../http-actions/analytics-actions';
import { AuthContext } from '../../providers/auth-provider';
import { NavigationInfoContext } from '../../providers/navigation-info-provider';
import Button from '../button';

/*
TODO
MAKE NAVBUTTONS LINKS
*/

const Hero = (props) => {
    const {
        state: { pathToFirstPart },
    } = useContext(NavigationInfoContext);

    console.log('FIRST PART OF COURSE', pathToFirstPart);

    const scrollToModules = () => {
        scrollTo('#currentlyActiveModules');
    };

    const scrollToAdditionalInformation = () => {
        scrollTo('#readMoreAboutIt');
    };

    const { state: authState } = useContext(AuthContext);

    const logPageNavigation = async (path: string, buttonId: string) => {
        void postAnalyticsData(authState.token, 'navigation', {
            navigate_to: path,
            type: 'front-page-navigation',
            buttonId,
        });
    };

    const navigateToPage = (path: string, buttonId: string) => () => {
        void logPageNavigation(path, buttonId);
        void navigate(path);
    };

    return (
        <Box
            component={'section'}
            sx={(theme) => ({
                backgroundColor: 'background.paper',
                pt: {
                    xs: theme.spacing(10),
                    sm: theme.spacing(12),
                },
                pb: {
                    xs: theme.spacing(8),
                    sm: theme.spacing(10),
                },
            })}
        >
            <Container maxWidth="lg">
                <Typography
                    sx={{
                        lineHeight: 0.9,
                        fontSize: {
                            xs: `${50 / 16}rem`,
                            md: `${100 / 16}rem`,
                        },
                        maxWidth: {
                            xs: 350,
                            md: 400,
                        },
                    }}
                    variant="h1"
                    gutterBottom
                >
                    {props.title}
                </Typography>

                <Typography
                    sx={{
                        maxWidth: 820,
                    }}
                    component="div"
                    variant="body1"
                    align="left"
                    paragraph
                >
                    {props.children}
                </Typography>

                <Box
                    sx={{
                        mt: 4,
                        display: 'flex',
                        flexDirection: {
                            xs: 'column',
                            sm: 'row',
                        },
                        justifyContent: {
                            sm: 'space-between',
                        },
                        alignItems: {
                            sm: 'flex-start',
                        },
                        maxWidth: {
                            sm: 420,
                        },
                    }}
                >
                    <Button
                        id="start-now"
                        color="inherit"
                        kind="fitech"
                        sx={{
                            color: 'fitech.contrastText',
                            backgroundColor: 'fitech.main',
                            marginBottom: (theme) => theme.spacing(1),
                            transition: (theme) =>
                                theme.transitions.create(['color', 'background-color']),
                            fontSize: {
                                xs: `${30 / 16}rem`,
                                md: `${36 / 16}rem`,
                            },
                            width: {
                                xs: 'inherit',
                                sm: 200,
                            },
                            '&:hover': {
                                color: 'common.black',
                            },
                        }}
                        elevation={0}
                        onClick={
                            props.singleCourse
                                ? navigateToPage(pathToFirstPart, 'start-now')
                                : scrollToModules
                        }
                    >
                        <Trans i18nKey="startNow" />!
                    </Button>

                    {
                        <Button
                            id="more-info-scroll"
                            kind="fitech"
                            color="inherit"
                            sx={{
                                backgroundColor: 'rgba(0, 0, 0, 0.12)',
                                fontSize: {
                                    xs: `${30 / 16}rem`,
                                    md: `${36 / 16}rem`,
                                },
                                width: {
                                    xs: 'inherit',
                                    sm: 200,
                                },
                            }}
                            elevation={0}
                            onClick={scrollToAdditionalInformation}
                        >
                            <Trans i18nKey="readMore" />
                        </Button>
                    }
                </Box>
            </Container>
        </Box>
    );
};

export default Hero;
