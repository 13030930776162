import { Typography } from '@mui/material';
import React from 'react';

const H1 = ({ children, ...rest }: { children: string }) => {
    const titleId = `${String(children)
        .replace(/[^a-öA-Ö -]/g, '')
        .toLowerCase()
        .split(' ')
        .join('-')}`;
    return (
        <Typography
            id={titleId}
            sx={{
                marginBottom: (theme) => theme.spacing(2),
                marginTop: (theme) => theme.spacing(6),
            }}
            variant="h1"
            {...rest}
        >
            {children}
        </Typography>
    );
};

export default H1;
