import { Container, Typography } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';
import ModuleCollectionListContent from '../../course-modules/module-collection-list-content';
import StartHereGraphic from './start-here-graphic';

export interface Props {
    moduleFilterCategory: string;
}

export default function MainModuleCollection(props: Props) {
    return (
        <Container
            maxWidth="lg"
            sx={{
                pt: 4,
                backgroundColor: 'white',
                boxShadow: '0 0 0 100vmax white',
                clipPath: 'inset(0 -100vmax)',
            }}
        >
            <Typography
                variant="h1"
                component="h2"
                align="center"
                gutterBottom
                id="module-collection-list-container"
            >
                <Trans i18nKey="courses" />
            </Typography>
            <Container disableGutters sx={{ position: 'relative', py: 8 }}>
                {props.moduleFilterCategory !== 'programmer' && <StartHereGraphic />}
                <ModuleCollectionListContent published />
            </Container>
        </Container>
    );
}
