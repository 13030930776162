import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const DefaultButton = styled(Button)({
    borderRadius: 0,
});

const FITechButton = styled(DefaultButton)(({ theme }) => ({
    fontFamily: '"Teko", "Helvetica", sans-serif',
    fontSize: theme.pxToRem(28),
    [theme.breakpoints.down('md')]: {
        fontSize: theme.pxToRem(24),
    },
    fontWeight: 400,
    letterSpacing: 'normal',
    lineHeight: 'normal',
    padding: '0px 12px',
    textTransform: 'none',
}));

const CustomButton = ({ kind = '', children, ...rest }) => {
    switch (kind) {
        case 'mui': {
            return <Button {...rest}>{children}</Button>;
        }
        case 'fitech': {
            return <FITechButton {...rest}>{children}</FITechButton>;
        }
        default: {
            return <DefaultButton {...rest}>{children}</DefaultButton>;
        }
    }
};

export default CustomButton;
