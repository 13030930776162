import { Box } from '@mui/material';
import React, { PropsWithChildren, useState } from 'react';
import RandomizedExperiment from '../../experiments/randomized-experiments/randomized-experiment';
import RandomizedExperimentGroup from '../../experiments/randomized-experiments/randomized-experiment-group';
import MainHero from './main-hero';
import MainLanguageSwitch from './main-language-switch';
import MainModuleCollection from './main-module-collection';
import MainModuleCollectionWithExperience from './main-module-collection-with-experience';

export default function MainLandingPage() {
    const [moduleFilterCategory, setModuleFilterCategory] = useState('all');

    return (
        <>
            <MainLanguageSwitch />
            <MainHero />
            <RandomizedExperiment name="2022-top-landing-page-experience-buttons">
                <RandomizedExperimentGroup id="all-courses" reactType="RandomizedExperimentGroup">
                    <MainModuleCollection moduleFilterCategory={moduleFilterCategory} />
                </RandomizedExperimentGroup>
                <RandomizedExperimentGroup
                    id="experience-buttons"
                    reactType="RandomizedExperimentGroup"
                >
                    <MainModuleCollectionWithExperience
                        moduleFilterCategory={moduleFilterCategory}
                        setModuleFilterCategory={setModuleFilterCategory}
                    />
                </RandomizedExperimentGroup>
            </RandomizedExperiment>
        </>
    );
}
