import { Container, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { Trans } from 'react-i18next';
import MainGraphic from './main-graphic';

export default function MainHero() {
    return (
        <Container
            component="section"
            maxWidth="lg"
            sx={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '40vh',
                py: 8,
                boxShadow: '0 0 0 100vmax white',
                clipPath: 'inset(0 -100vmax)',
                position: 'relative',
            }}
        >
            <MainGraphic />
            <Typography
                variant="h1"
                sx={{ fontSize: { xs: '72px', sm: '116px' }, zIndex: 2 }}
                gutterBottom
            >
                <Trans i18nKey="welcomeTitle" />
            </Typography>
            <Container maxWidth="sm" sx={{ zIndex: 2 }}>
                <Typography variant="body1" align="center">
                    <Trans i18nKey="welcomeDescription" />
                </Typography>
            </Container>
        </Container>
    );
}
