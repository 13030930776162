import { Box } from '@mui/material';
import { MDXProvider } from '@mdx-js/react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

import ModuleCollection from '../components/course-modules/course-card-collection'; // MAYBE REWORK THIS
import ModuleList from '../components/course-modules/module-list';
import Footer from '../components/footer';
import AdditionalDetails from '../components/landing-page/additional-details'; // MAYBE REWORK THIS
import BannerAd from '../components/landing-page/banner-ad';
import Hero from '../components/landing-page/hero';
import { H1, H2, H3 } from '../components/text-components';
import HeroWithGraphic from '../components/landing-page/hero-with-graphic';
import ModuleCollectionList from '../components/course-modules/module-collection-list';
import SuperHero from '../components/landing-page/super-hero';
import useAppBarHeight from '../hooks/useAppBarHeight';
import MainLandingPage from '../components/landing-page/main-landing';

const shortcodes = {
    h1: H1,
    h2: H2,
    h3: H3,
    ModuleCollection,
    AdditionalDetails,
    Hero,
    SuperHero,
    ModuleList,
    ModuleCollectionList,
    BannerAd,
    HeroWithGraphic,
    MainLandingPage
};

export default function PageTemplate({ data, ...props }) {
    const appBarHeight = useAppBarHeight();
    return (
        <>
            <Box
                component={'main'}
                id="skip-nav-target"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    minHeight: (theme) => `calc(100vh - ${appBarHeight}px)`,
                }}
            >
                <MDXProvider components={shortcodes}>
                    <MDXRenderer>{data.mdx.body}</MDXRenderer>
                </MDXProvider>
            </Box>
            <Footer {...props} />
        </>
    );
}

export const pageQuery = graphql`
    query FrontPageQuery($id: String) {
        mdx(id: { eq: $id }) {
            id
            body
        }
    }
`;
