import { Container, Grid, Typography } from '@mui/material';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import CourseCard from './course-card';
import { MODULE_COLLECTIONS } from '../../config';

const moduleCollectionListContentQuery = graphql`
    query ModuleCollectionListContentQuery {
        allMdx(
            filter: { fields: { pageType: { in: ["course-index-page", "module-index-page"] } } }
            sort: { fields: [frontmatter___order], order: ASC }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        metatext
                        published
                        image
                        release
                        workload
                    }
                    fields {
                        slug
                        collection
                    }
                }
            }
        }
    }
`;

const ModuleCollectionListContent = (props) => {
    const { filterFunction = (n: any) => true } = props;

    const data = useStaticQuery(moduleCollectionListContentQuery);

    const filterPublished = (n: any) => n.node.frontmatter.published === props.published;

    const publishedModuleCollections = data?.allMdx?.edges
        .filter((n: any) => {
            const module = MODULE_COLLECTIONS[n.node.fields.collection];
            return module?.title === n.node.frontmatter.title;
        })
        .filter(filterPublished)
        .map((n: any) => n.node.fields.collection);

    const filterModuleCollections = (n: any) => {
        const module = MODULE_COLLECTIONS[n.node.fields.collection];
        if (module?.type === 'single-course') {
            return module.title === n.node.frontmatter.title;
        } else if (
            module?.type === 'multi-course' &&
            publishedModuleCollections.includes(n.node.fields.collection)
        ) {
            return module.title !== n.node.frontmatter.title;
        } else return false;
    };

    const nodesToShow = data?.allMdx?.edges
        .filter(filterPublished)
        .filter(filterModuleCollections)
        .filter(filterFunction);

    return (
        nodesToShow && (
            <Grid container spacing={2} direction="row">
                {nodesToShow.map(({ node }: any) => (
                    <Grid item xs={12} sm={6} md={4} key={'gi-' + node.id}>
                        <CourseCard
                            key={node.id}
                            slug={node.fields.slug}
                            title={node.frontmatter.title}
                            metatext={node.frontmatter.metatext}
                            image={node.frontmatter.image}
                            published={props.published}
                            release={node.frontmatter.release}
                            workload={node.frontmatter.workload}
                        />
                    </Grid>
                ))}
            </Grid>
        )
    );
};

export default ModuleCollectionListContent;
